import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Section } from '../components/section';
import { Anchors } from '../components/navigation';
import styles from './index.module.scss';

const IndexPage = ({ location }: { location: Location }) => (
  <Layout location={location}>
    <SEO title="SimpleSoft software house - .Net developer job" description="job offer, .net developer, C#, F#, .net core" />
    <Section anchor={''}>
      <h1>.Net Developer</h1>
        <h3>Brief project description:</h3>
        <p>We are creating selling platform that allows users to buy, sell and exchange event tickets. 
        Platform has some simple and more advanced functions like monitoring real time prices on the market. 
        Currently the project is in its end stage of first version and we are just about to go live. 
        Platform is written by us from scratch and contains three main modules: user Portal, backend services and CMS/admin panel. 
        Project is created to be scalable and well performing. We are using various technologies depending on the module:
        </p>
        <ul>
            <li> React,  Redux, Angular, TypeScript </li>
            <li>.Net core, C#, F#, </li>
            <li>Docker, Kubernetes, Azure, Service Bus, SQL server</li>
        </ul>
        <h3>Job description</h3>
        <p>
          We are looking for a software developer with minimum of 1 year experience in creating web applications. Your main focus will be to backend side of the application which is created using Giraffe Framework/ F# and .NET Core. 
          There are services providing REST API for frontend and queue subscribers for handling some more advanced side actions and automation. 
          Knowing F# or functional programming is advantageous but codebase is in F# so willing to learn is essential.
        </p>
        <h4>Requirements and Experience: (essential)</h4>
        <ul>
          <li>Minimum of 1 year experience on working with .NET backend application</li>
          <li>Minimum of 1 year experience on writing web application including REST APIs and MVC websites</li>
          <li>Use and knowledge of Docker containers and related databases including SQL server</li>
        </ul>
        
        <h4>Advantageous skills, but not essential:</h4>
        <ul>
          <li>Experience with F# and/or functional languages</li>
          <li>Experience with frontend frameworks like React or Angular</li>
          <li>Azure knowledge</li>
        </ul>
        <h4>Tech stack:</h4>
        <ul>
          <li>F#, C#, .NET Core</li>
          <li>Giraffe framework</li>
          <li>Docker, Kubernetes, Azure, service bus, SQL server</li>
        </ul>
    </Section>
  </Layout>
);

export default IndexPage;
